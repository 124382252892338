import { Employment, WorkSchedule, ChatWritePossibility } from '@hh.ru/types-hh-microcore';

import { BrandSnippet } from 'src/models/brandSnippetEditor/brandSnippet.types';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';
import { VacancyProperties } from 'src/models/vacancyProperties';
import { BrandingType } from 'src/models/vacancyView';
import { Badge, VacancyViewInsider, VacancyAddress } from 'src/models/vacancyView.types';
import { ExperienceOption } from 'src/types/search/common/criteria';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';
import UserLabel from 'src/utils/constants/userLabels';

export const ACTIVITY_ONLINE = 'online';

export interface VacancyEmployerManager {
    '@hhid': number;
    '@managerId': number;
    '@userId': number;
    latestActivity: typeof ACTIVITY_ONLINE | null;
    '@firstName'?: string;
    '@lastName'?: string;
    '@middleName'?: string;
}

export interface VacancySnippet {
    req?: string;
    resp?: string;
    skill?: string;
    cond?: string;
    desc?: string;
}

export interface VacancyCompany {
    '@category': string;
    '@countryId': number;
    '@showSimilarVacancies': boolean;
    '@state': string;
    '@trusted': boolean;
    employerOrganizationFormId: number;
    accreditedITEmployer?: boolean;
    badges?: { badge: Badge[] };
    id: number;
    name: string;
    visibleName: string;
    department?: {
        '@code': string;
        '@name': string;
    };
    logos?: {
        '@showInSearch'?: boolean;
        logo: Array<{
            '@type': string;
            '@url': string;
        }>;
    };
    employerReviews?: {
        totalRating: string;
        reviewsCount: number;
    };
}

export interface VacancySearchItem {
    '@click'?: string;
    '@responseLetterRequired': boolean;
    '@showContact': boolean;

    show_question_input?: boolean;
    'hr-brand': {
        type: string;
        '@url': string;
        '@year': number;
    };
    archived?: boolean;
    acceptIncompleteResumes: boolean;
    acceptTemporary: boolean;
    address?: VacancyAddress;
    area: {
        '@id': number;
        name: string;
        path: string;
    };
    branding?: {
        type: BrandingType;
    };
    brandSnippet: BrandSnippet;
    canBeShared: boolean;
    chatWritePossibility: ChatWritePossibility;
    clickUrl?: string;
    company: VacancyCompany;
    compensation: VacancyCompensation;
    creationSite: string;
    creationSiteId: number;
    creationTime: string;
    displayHost: string;
    employerManager: VacancyEmployerManager;
    employment: { '@type': Employment };
    '@isAdv'?: boolean;
    isVacancyOfTheDay?: boolean;
    inboxPossibility: boolean;
    insider?: VacancyViewInsider;
    immediateRedirectUrl?: string;
    lastChangeTime: {
        '@timestamp': number;
    };
    links: {
        desktop: string;
        mobile?: string;
    };
    markedShowContacts: boolean;
    metallic: string;
    name: string;
    notify: boolean;
    publicationTime: {
        '@timestamp': number;
        $: string;
    };
    responsesCount: number;
    snippet: VacancySnippet;
    searchDebug: {
        score: number;
        explanation: string;
    };
    searchRid?: string;
    totalResponsesCount: number;
    type: string;
    userLabels: UserLabel[];
    vacancyId: number;
    viewUrl: string;
    workExperience: ExperienceOption;
    workSchedule: WorkSchedule;
    '@workSchedule': ScheduleCamelCase;

    online_users_count?: number;
    vacancyProperties: VacancyProperties;
    closedForApplicants: boolean;
}
