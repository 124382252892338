import { useCallback, useRef } from 'react';
import classnames from 'classnames';

import { VSpacing, HSpacingContainer, HSpacing, Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { ArchiveboxOutlinedSize16, InfoCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChatikButton from 'src/components/VacancySearchItem/ChatikButton';
import VacancySearchItemResponseButton from 'src/components/VacancySearchItem/ResponseButton';
import ContactsButton from 'src/components/VacancySearchItem/ShowContactsButton';
import { useApplicantSearchQuestionExp } from 'src/components/VacancySearchItem/hooks/useApplicantSearchQuestionExp';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import { useWorkflowState } from 'src/components/VacancySearchItem/hooks/useWorkflowState';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { VacancyAddress } from 'src/models/vacancyView.types';
import { sendAdvSearchVacancyAnalytics, AdvAnalyticsTypes } from 'src/utils/sendAdvAnalytics';

import styles from './styles.less';

const RESPONSE_STATUS_TYPOGRAPHY = 'label-2-regular';

const Features = {
    hideInterfaceForModerators: 'hide_interface_for_moderators',
};

const TrlKeys = {
    movedToArchive: 'vacancySearch.vacancyCard.movedToArchive',
    archived: 'applicant.negotiations.vacancyArchived',
    responded: 'vacancySearchResults.applicantflags.responded',
    youWereInvited: 'vacancySearchResults.applicantflags.youWereInvited',
    discard: 'vacancySearchResults.applicantflags.rejected',
    chat: 'vacancySearchResults.chat',
};

interface MainButtonsProps {
    vacancyId: number;
    vacancySource: Source;
    companyId: number;
    companyName: string;
    showContact: boolean;
    showQuestionInput?: boolean;
    chatWritePossibility: ChatWritePossibility;
    vrImmediateRedirectUrl?: string;
    vacancyType: string;
    clickUrl?: string;
    vacancyOfTheDayClickUrl?: string;
    address?: VacancyAddress;
    isAdv?: boolean;
    isArchived: boolean;
    employerManagerName: string;
}

const MainButtons: TranslatedComponent<MainButtonsProps> = ({
    vacancyId,
    vacancySource,
    companyId,
    companyName,
    showContact,
    showQuestionInput,
    chatWritePossibility,
    vrImmediateRedirectUrl,
    clickUrl,
    vacancyOfTheDayClickUrl,
    address,
    isAdv,
    isArchived,
    employerManagerName,
    trls,
}) => {
    const { isXS } = useBreakpoint();
    const hideInterfaceForModerators = useSelector(({ features }) => features[Features.hideInterfaceForModerators]) as
        | string
        | undefined;
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();

    const handleContactsClickRef = useRef({});
    const userType = useSelector((state) => state.userType);
    const userId = useSelector(({ userId }) => userId);
    const currentEmployerId = useSelector((state) => state.employerId);
    const shouldHideInterfaceForModerators = hideInterfaceForModerators?.split(',')?.includes(userId);
    const resolveContactButtonVisibility = () => {
        if (isXS && userType === UserType.Anonymous) {
            return false;
        }
        if (userType === UserType.Employer && Number(currentEmployerId) !== companyId) {
            return false;
        }
        return showContact;
    };
    const isContactsButtonVisible = resolveContactButtonVisibility();
    const isResponseButtonVisible = userType !== UserType.Employer || vacancySource === Source.EmployerVacancy;
    const isAnyButtonVisibleMagritte = isResponseButtonVisible || isContactsButtonVisible || isArchived;

    const onShowContactsButtonClick = useCallback(
        () => sendAdvSearchVacancyAnalytics({ clickUrl, isAdv, analyticsType: AdvAnalyticsTypes.ContactsButton }),
        [clickUrl, isAdv]
    );

    const { isResponded, isInvited, isDiscarded, showWorkflowStatus } = useWorkflowState(vacancyId);

    const { isQuestionAvailable } = useApplicantSearchQuestionExp(showQuestionInput, false);

    if (shouldHideInterfaceForModerators) {
        return null;
    }

    const getWorkflowStatusElement = (isNarrowContainer: boolean) => {
        if (isResponded) {
            if (isNarrowContainer) {
                return (
                    <div
                        className={classnames(
                            styles.workflowStatusContainerMobile,
                            styles.workflowStatusContainerResponded
                        )}
                    >
                        <InfoCircleOutlinedSize16 initial="accent" />
                        <HSpacing default={12} />
                        <Text typography={RESPONSE_STATUS_TYPOGRAPHY} style="accent">
                            {trls[TrlKeys.responded]}
                        </Text>
                    </div>
                );
            }
            return (
                <div
                    data-qa="vacancy-serp__vacancy_responded"
                    className={classnames(styles.workflowStatusContainer, styles.workflowStatusContainerResponded)}
                >
                    <InfoCircleOutlinedSize16 initial="accent" />
                    <HSpacing default={8} />
                    <Text typography={RESPONSE_STATUS_TYPOGRAPHY} style="accent">
                        {trls[TrlKeys.responded]}
                    </Text>
                </div>
            );
        } else if (isInvited) {
            if (isNarrowContainer) {
                return (
                    <div
                        className={classnames(
                            styles.workflowStatusContainerMobile,
                            styles.workflowStatusContainerInvited
                        )}
                    >
                        <InfoCircleOutlinedSize16 initial="positive" />
                        <HSpacing default={12} />
                        <Text typography={RESPONSE_STATUS_TYPOGRAPHY} style="positive">
                            {trls[TrlKeys.youWereInvited]}
                        </Text>
                    </div>
                );
            }
            return (
                <div className={classnames(styles.workflowStatusContainer, styles.workflowStatusContainerInvited)}>
                    <InfoCircleOutlinedSize16 initial="positive" />
                    <HSpacing default={8} />
                    <Text typography={RESPONSE_STATUS_TYPOGRAPHY} style="positive">
                        {trls[TrlKeys.youWereInvited]}
                    </Text>
                </div>
            );
        } else if (isDiscarded) {
            if (isNarrowContainer) {
                return (
                    <div
                        className={classnames(
                            styles.workflowStatusContainerMobile,
                            styles.workflowStatusContainerDiscarded
                        )}
                    >
                        <InfoCircleOutlinedSize16 initial="warning-secondary" />
                        <HSpacing default={12} />
                        <Text typography={RESPONSE_STATUS_TYPOGRAPHY} style="warning-secondary">
                            {trls[TrlKeys.discard]}
                        </Text>
                    </div>
                );
            }
            return (
                <div className={classnames(styles.workflowStatusContainer, styles.workflowStatusContainerDiscarded)}>
                    <InfoCircleOutlinedSize16 initial="warning-secondary" />
                    <HSpacing default={8} />
                    <Text typography={RESPONSE_STATUS_TYPOGRAPHY} style="warning-secondary">
                        {trls[TrlKeys.discard]}
                    </Text>
                </div>
            );
        }

        return null;
    };

    if (!isAnyButtonVisibleMagritte) {
        return null;
    }

    if (isArchived) {
        return (
            <>
                <div className={wideCardContainerClassName}>
                    <VSpacing default={12} />

                    <div data-qa="vacancy-serp__vacancy_archived" className={styles.archived}>
                        <ArchiveboxOutlinedSize16 initial="secondary" />
                        <HSpacing default={8} />
                        <Text Element="span" typography={RESPONSE_STATUS_TYPOGRAPHY} style="secondary">
                            {trls[TrlKeys.archived]}
                        </Text>
                    </div>
                </div>
                <div className={narrowCardContainerClassName}>
                    <VSpacing default={12} />

                    <div className={styles.archivedMobile}>
                        <ArchiveboxOutlinedSize16 initial="secondary" />
                        <HSpacing default={24} />
                        <Text Element="span" typography={RESPONSE_STATUS_TYPOGRAPHY} style="secondary">
                            {trls[TrlKeys.archived]}
                        </Text>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <VSpacing default={12} />

            <div className={wideCardContainerClassName}>
                <HSpacingContainer default={12}>
                    {showWorkflowStatus ? (
                        <>
                            {getWorkflowStatusElement(false)}

                            <ChatikButton vacancyId={vacancyId} vacancySource={vacancySource}>
                                {trls[TrlKeys.chat]}
                            </ChatikButton>
                        </>
                    ) : (
                        <VacancySearchItemResponseButton
                            vacancyId={vacancyId}
                            vacancySource={vacancySource}
                            employerId={companyId}
                            vrImmediateRedirectUrl={vrImmediateRedirectUrl}
                            clickUrl={clickUrl}
                            isAdv={isAdv}
                            vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                            isResponseButtonVisible={isResponseButtonVisible}
                        />
                    )}

                    <ContactsButton
                        showContact={isContactsButtonVisible}
                        showQuestionInput={showQuestionInput}
                        vacancyId={vacancyId}
                        companyName={companyName}
                        address={address}
                        employerId={companyId}
                        chatWritePossibility={chatWritePossibility}
                        employerManagerName={employerManagerName}
                        onClick={onShowContactsButtonClick}
                        handleContactsClickRef={handleContactsClickRef}
                    />
                </HSpacingContainer>
            </div>

            <div className={narrowCardContainerClassName}>
                {showWorkflowStatus ? (
                    <>
                        {getWorkflowStatusElement(true)}

                        <VSpacing default={12} />

                        <HSpacingContainer default={12}>
                            <div className={styles.afterResponseButtonMobile}>
                                <ChatikButton vacancyId={vacancyId} vacancySource={vacancySource} stretched>
                                    {trls[TrlKeys.chat]}
                                </ChatikButton>
                            </div>

                            {(isContactsButtonVisible || isQuestionAvailable) && (
                                <div className={styles.afterResponseButtonMobile}>
                                    <ContactsButton
                                        showContact={isContactsButtonVisible}
                                        showQuestionInput={showQuestionInput}
                                        companyName={companyName}
                                        vacancyId={vacancyId}
                                        address={address}
                                        employerId={companyId}
                                        employerManagerName={employerManagerName}
                                        chatWritePossibility={chatWritePossibility}
                                        onClick={onShowContactsButtonClick}
                                        handleContactsClickRef={handleContactsClickRef}
                                        stretched
                                    />
                                </div>
                            )}
                        </HSpacingContainer>
                    </>
                ) : (
                    <HSpacingContainer default={12}>
                        <VacancySearchItemResponseButton
                            vacancyId={vacancyId}
                            vacancySource={vacancySource}
                            employerId={companyId}
                            vrImmediateRedirectUrl={vrImmediateRedirectUrl}
                            clickUrl={clickUrl}
                            isAdv={isAdv}
                            vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                            isNoPopups
                            isStretched
                            isResponseButtonVisible={isResponseButtonVisible}
                        />
                        <ContactsButton
                            showContact={isContactsButtonVisible}
                            showQuestionInput={showQuestionInput}
                            companyName={companyName}
                            vacancyId={vacancyId}
                            address={address}
                            employerId={companyId}
                            chatWritePossibility={chatWritePossibility}
                            onClick={onShowContactsButtonClick}
                            handleContactsClickRef={handleContactsClickRef}
                            employerManagerName={employerManagerName}
                        />
                    </HSpacingContainer>
                )}
            </div>
        </>
    );
};

export default translation(MainButtons);
